import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/App.css";
import ItemPage from "./pages/ItemPage";
import CollectionPage from "./pages/CollectionPage";
import { CollectionFilterSectionProvider } from "./contexts/CollectionPage/FilterSectionContext";
import { CreatorFilterSectionProvider } from "./contexts/CreatorPage.js/FilterSectionContext";
import "./api/metaMaskApi";
import { FolderFilterSectionProvider } from "./contexts/FolderPage/FolderFilterSectionContext";
import ProvenanceReportPage from "./pages/Public/ProvenanceReportPage";
import UserProvenancePage from "./pages/UserProvenancePage";
import DefaultPage from "./pages/DefaultPage";
import ScrollToTop from "./components/UtilComponents/ScrollToTop";
import ErrorBoundary from "./pages/ErrorBoundary";
import CreatorPageParent from "./pages/CreatorPageParent";
import SubCollectionPage from "./pages/SubCollectionPage";
import {
  niftyMetaGirlWalletAddress,
  alphatrilogyWalletAddress,
  REACT_APP_BACKEND_API_BASE_URL,
} from "./api/backend_domains/backend_api_domains";
import axios from "axios";
import NftTransactionsActivity from "./pages/NftTransactionsActivity";
import { useDispatch } from "react-redux";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { theme } from "./styles/muiTheme";

async function healthCheck() {
  try {
    const serverUrl = REACT_APP_BACKEND_API_BASE_URL;
    const response = await axios.get(serverUrl + "/health");
    if (response.status === 200) {
      console.log("Server is up and running.");
      return { serverHealthy: true, rateLimit: false };
    } else if (response.status === 429) {
      console.log("Rate Limit 429: Too many requests in short timespan");
      return { serverHealthy: true, rateLimit: true };
    } else {
      console.log(response);
      throw new Error("Server is down");
    }
  } catch (error) {
    if (error.response && error.response.status === 429) {
      return { serverHealthy: true, rateLimit: true };
    } else {
      return { serverHealthy: false, rateLimit: false };
    }
  }
}

function App() {
  const [rateLimit, setRateLimit] = useState(false);
  const [pageNotFound, setPageNotFound] = useState(false);
  let walletAddressFromParams = alphatrilogyWalletAddress;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: "UPDATE_WALLET_ADDRESS",
      payload: walletAddressFromParams,
    });
  }, [walletAddressFromParams, dispatch]);
  useEffect(() => {
    async function checkHealth() {
      const result = await healthCheck();
      setRateLimit(result.rateLimit);
    }
    checkHealth();
  }, []);

  if (rateLimit)
    return (
      <div>
        Too many requests have been made in a short period. Requests are
        temporarily blocked. Please try again later.
      </div>
    );
  return (
    // <ThemeProvider theme={theme}>
    <Router>
      <ScrollToTop />
      <Routes>
        <Route
          path="/"
          element={
            <ErrorBoundary>
              <CreatorFilterSectionProvider>
                <CreatorPageParent />
              </CreatorFilterSectionProvider>
            </ErrorBoundary>
          }
        />
        <Route
          exact
          strict
          sensitive={false}
          path="/nft_transfers_activity"
          element={
            <ErrorBoundary>
              <NftTransactionsActivity />
            </ErrorBoundary>
          }
        />
        <Route
          exact
          strict
          sensitive={false}
          path="/collection/:collectionId/"
          element={
            <ErrorBoundary>
              <CollectionFilterSectionProvider>
                <CollectionPage setPageNotFound={setPageNotFound} />
              </CollectionFilterSectionProvider>
            </ErrorBoundary>
          }
        />
        <Route
          exact
          strict
          sensitive={false}
          path="/collection/:collectionId/slug/:collectionSlug"
          element={
            <ErrorBoundary>
              <CollectionFilterSectionProvider>
                <SubCollectionPage />
              </CollectionFilterSectionProvider>
            </ErrorBoundary>
          }
        />
        <Route
          exact
          strict
          sensitive={false}
          path="/collection/:collectionId/created-by/:collectionCreator"
          element={
            <ErrorBoundary>
              <CollectionFilterSectionProvider>
                <SubCollectionPage />
              </CollectionFilterSectionProvider>
            </ErrorBoundary>
          }
        />
        <Route
          path="/collection/:collectionId?/id/:itemId?"
          element={
            <ErrorBoundary>
              <ItemPage />
            </ErrorBoundary>
          }
        />
        <Route
          exact
          strict
          sensitive={false}
          path="/provenance_report/collection/:collectionId/:itemId"
          element={
            <ErrorBoundary>
              <ProvenanceReportPage />
            </ErrorBoundary>
          }
        />
        <Route
          exact
          strict
          sensitive={false}
          path="/provenance_report/collection/:collectionId/:itemId/:pdf"
          element={
            <ErrorBoundary>
              <ProvenanceReportPage />
            </ErrorBoundary>
          }
        />
        <Route
          exact
          strict
          sensitive={false}
          path="/provenance"
          element={
            <ErrorBoundary>
              <FolderFilterSectionProvider>
                <UserProvenancePage />
              </FolderFilterSectionProvider>
            </ErrorBoundary>
          }
        />
        <Route
          path="/login"
          element={
            <ErrorBoundary>
              <LoginPage />
            </ErrorBoundary>
          }
        />
        <Route
          exact
          strict
          sensitive={false}
          path="*"
          element={
            <ErrorBoundary>
              <DefaultPage />
            </ErrorBoundary>
          }
        />
      </Routes>
    </Router>
    // </ThemeProvider>
  );
}

export default App;
